import React, { useState, useContext } from "react";
import Stars from "./Stars";
import { GlobalDataContext } from '../../context/context'


const Faq =() => {
    const { rpdata } = useContext(GlobalDataContext);
    

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
      



    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1"> 
            {
                rpdata?.works?.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-3xl bg-gray-100 w-auto">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src={item.gallery}
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        <span className="text-[20px] font-semibold"> {item.name} </span>
                                        <Stars score={item.subdescription} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item?.Date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item.description}</ReadMore>
                            </div>
                            <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[25px] h-[25px] flex items-center">
                                    <img src={item.gallery[1]} alt="agni" className="w-full mx-auto" />
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item.enlace}>{item.red}</a>
                                </div>
                            </div>
                        </article>
                    );
                })
            }
        </section>
    );
}

export default Faq;